<template>
  <div>
    <div class="col-12 mt-4">
      <aad-back></aad-back>
    </div>
    <p class="my-4" v-if="data == undefined || data.length == 0">
      <aad-title>{{ type }} Details</aad-title>
      <aad-loader></aad-loader>
    </p>
    <p class="my-4" v-else>
      <aad-title>{{ type }} Not Found</aad-title>
      <span class="font-weight-bold"> {{ type }} not found</span>, please return
      to the {{ type }} search page and try again.
    </p>
  </div>
</template>

<script>
export default {
  props: ["type", "router-to", "data"],
};
</script>
