<template>
  <div>
    <a
      href="#"
      @click="saveNotes"
      class="btn btn-primary-solid d-inline-block mr-2"
      >Save Notes</a
    >
    <p class="small d-inline-block mb-0" v-if="hasUnsavedChanges">
      You have unsaved changes!
    </p>
    <transition name="fade">
      <p
        class="small d-inline-block mb-0 font-italic green-font"
        v-if="showSavedMessage"
      >
        Note Saved!
      </p>
    </transition>
    <textarea
      class="mt-2 w-100 form-control"
      rows="15"
      v-model="currentNoteBody"
    ></textarea>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

// Note, the beforeunload event will not work on router change, that will need to be checked in the parent control.
export default {
  data() {
    return {
      currentNoteBody: "",
      showSavedMessage: false,
    };
  },
  props: ["note-type", "link-id", "title"],
  created() {
    window.addEventListener("beforeunload", (e) => this.promptSaveChanges(e));
  },

  beforeDestroy() {
    window.removeEventListener("beforeunload", this.promptSaveChanges);
  },
  watch: {
    noteRecord: {
      immediate: true,
      handler() {
        if (this.noteRecord && this.currentNoteBody == "") {
          this.currentNoteBody = this.noteRecord.NoteBody;
        }
      },
    },
  },
  methods: {
    promptSaveChanges(e) {
      if (this.hasUnsavedChanges) {
        e.preventDefault();

        e.returnValue = "If you leave now your notes will not save.";
      }
    },
    saveNotes(e) {
      // don't jump on the page from clicking the href="#"
      e.preventDefault();

      // Send data to the save note action in store
      this.$store.dispatch("saveNote", {
        LinkId: this.linkId,
        NoteBody: this.currentNoteBody,
        NoteType: this.noteType,
        Title: this.title,
      });

      // Trigger saved message
      this.showSavedMessage = true;
      setTimeout(
        function () {
          this.showSavedMessage = false;
        }.bind(this),
        5000
      );
    },
  },
  computed: {
    ...mapGetters(["getNoteByIdAndType"]),
    noteRecord: function () {
      return this.getNoteByIdAndType({
        NoteType: this.noteType,
        LinkId: this.linkId,
      });
    },
    hasUnsavedChanges: function () {
      if (!this.noteRecord) {
        return this.currentNoteBody != "";
      } else {
        return this.currentNoteBody != this.noteRecord.NoteBody;
      }
    },
  },
};
</script>

<style scoped>
.fade-enter-active {
  transition: all 0.3s ease;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.green-font {
  color: green;
}
</style>
