var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.speaker)?_c('details-loading',{attrs:{"type":"Speaker","router-to":"Speakers","data":_vm.allSpeakers}}):_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-12"},[_c('aad-back')],1),_c('aad-title',{staticClass:"w-100"},[_vm._v(_vm._s(_vm.speaker.DisplayName))]),_c('div',{staticClass:"col-lg-4 p-1"},[(_vm.speaker.PhotoURL)?_c('img',{staticClass:"speaker-photo",attrs:{"src":_vm.speaker.PhotoURL,"alt":"Image of Speaker"}}):_c('img',{staticClass:"speaker-photo",attrs:{"src":_vm.getSpeakerPicture(_vm.speaker.PersonId),"alt":'image of' + _vm.speaker.DisplayName}}),(_vm.anyDetails)?_c('div',{staticClass:"meeting-app-info-block"},[_c('div',{staticClass:"meeting-app-info-header row"},[_c('h4',[_vm._v("Speaker Information")])]),_c('div',{staticClass:"meeting-app-info-body"},[(_vm.speaker.Facebook_URL)?_c('div',{staticClass:"row meeting-app-info-item"},[_c('a',{attrs:{"href":_vm.speaker.Facebook_URL},on:{"click":function($event){return _vm.logLinkClick(
                  'bio_facebook',
                  _vm.speaker.Facebook_URL,
                  'speaker_details'
                )}}},[_vm._v("Facebook")])]):_vm._e(),(_vm.speaker.Twitter_URL)?_c('div',{staticClass:"row meeting-app-info-item"},[_c('a',{attrs:{"href":_vm.twitterLink},on:{"click":function($event){return _vm.logLinkClick('bio_twitter', _vm.twitterLink, 'speaker_details')}}},[_vm._v("Twitter "),(_vm.speaker.Twitter_URL.startsWith('@'))?_c('span',[_vm._v("- "+_vm._s(_vm.speaker.Twitter_URL))]):_vm._e()])]):_vm._e(),(_vm.speaker.Linkedin_URL)?_c('div',{staticClass:"row meeting-app-info-item"},[_c('a',{attrs:{"href":_vm.speaker.Linkedin_URL},on:{"click":function($event){return _vm.logLinkClick(
                  'bio_linkedin',
                  _vm.speaker.Linkedin_URL,
                  'speaker_details'
                )}}},[_vm._v("LinkedIn")])]):_vm._e(),(_vm.speaker.Website)?_c('div',{staticClass:"row meeting-app-info-item"},[_c('a',{attrs:{"href":_vm.speaker.Website},on:{"click":function($event){return _vm.logLinkClick(
                  'bio_website',
                  _vm.speaker.Website,
                  'speaker_details'
                )}}},[_vm._v("Website")])]):_vm._e()])]):_vm._e()]),_c('div',{staticClass:"p-1 col-lg-8"},[(_vm.speaker.Bio)?_c('div',{staticClass:"p-3"},[_c('h4',[_vm._v("Speaker Bio:")]),_c('p',[_vm._v(_vm._s(_vm.speaker.Bio))])]):_vm._e(),_c('div',{staticClass:"meeting-app-info-block w-100"},[_c('div',{staticClass:"meeting-app-info-header row"},[_c('h4',[_vm._v("Session Schedule")])]),(_vm.speaker.Sessions && _vm.speaker.Sessions.length > 0)?_c('div',{staticClass:"meeting-app-info-body"},_vm._l((_vm.speaker.Sessions.slice().sort(
              function (a, b) { return a.StartTime.seconds - b.StartTime.seconds; }
            )),function(session){return _c('div',{key:session.SessionId},[_c('div',{staticClass:"row meeting-app-info-item"},[_c('div',[_c('router-link',{staticClass:"text-link-light-bg",attrs:{"to":{
                    name: 'SessionDetails',
                    params: { id: session.SessionId },
                  }},nativeOn:{"click":function($event){return _vm.logNavigationToSession(
                      session.SessionId,
                      session.SessionCode
                    )},"mouseup":function($event){if('button' in $event && $event.button !== 1){ return null; }return _vm.logNavigationToSession(
                      session.SessionId,
                      session.SessionCode
                    )}}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(session.SessionCode)+" - "+_vm._s(session.Name)+" ")])]),_vm._v(" - "),_c('session-time-string',{attrs:{"type":"full-range","session":session}})],1)])])}),0):_vm._e()]),(_vm.speaker.Disclosures)?_c('div',{staticClass:"meeting-app-info-block w-100"},[_c('div',{staticClass:"meeting-app-info-header row"},[_c('h4',[_vm._v("Disclosures")])]),_c('div',{staticClass:"meeting-app-info-body"},_vm._l((_vm.speaker.Disclosures.replaceAll(
              '\&ndash\;',
              '-'
            ).split(';')),function(disclosure){return _c('div',{key:disclosure,staticClass:"row meeting-app-info-item"},[(disclosure.trim().startsWith('no financial'))?_c('span',[_vm._v("No financial relationships exist with ineligble companies.")]):_c('span',[_vm._v(_vm._s(disclosure))])])}),0)]):_vm._e(),(_vm.speaker.Handouts && _vm.speaker.Handouts.length > 0)?_c('div',{staticClass:"meeting-app-info-block"},[_c('div',{staticClass:"meeting-app-info-header row"},[_c('h4',[_vm._v("Handouts")])]),(_vm.isLoggedIn)?_c('div',{staticClass:"meeting-app-info-body"},_vm._l((_vm.speaker.Handouts),function(handout){return _c('div',{key:handout.HandoutURL,staticClass:"row meeting-app-info-item"},[_c('a',{attrs:{"href":handout.HandoutURL,"target":"_blank"},on:{"click":function($event){return _vm.logHandoutClick(handout.HandoutTitle, handout.HandoutURL)}}},[_vm._v(_vm._s(handout.HandoutTitle))])])}),0):_c('div',{staticClass:"meeting-app-info-body"},[_c('div',{staticClass:"row meeting-app-info-item"},[_c('router-link',{staticClass:"text-link-light-bg",attrs:{"to":{
                name: 'Login',
              }}},[_vm._v(" Login to View Handouts ")])],1)])]):_vm._e()]),(_vm.isLoggedIn)?_c('div',{staticClass:"w-100"},[_c('hr'),_c('attendee-notes',{ref:"notes",staticClass:"w-100",attrs:{"note-type":"speaker","link-id":_vm.speaker.id,"title":_vm.speaker.DisplayName}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }