<template>
  <span>
    <span v-if="type == 'start-date'">
      {{ startDate }}
    </span>
    <span v-else-if="type == 'start-time'">
      {{ startDateTime }}
    </span>
    <span v-else-if="type == 'end-date'">
      {{ endDate }}
    </span>
    <span v-else-if="type == 'end-time'">
      {{ endDateTime }}
    </span>
    <span v-else-if="type == 'full-range' || type == undefined">
      {{ startDate }}<span v-if="startDate == endDate" class="mx-2">|</span
      ><span v-else>;</span> {{ startDateTime }} -
      <span v-if="startDate != endDate">{{ endDate }};</span>
      {{ endDateTime }}
    </span>
  </span>
</template>

<script>
import { formatDate, formatTime } from "@/mixins/formatDate.js";

export default {
  props: ["session", "type"],
  computed: {
    startTime() {
      if (this.session.StartTime) {
        return new Date(this.session.StartTime.seconds * 1000);
      } else {
        // For some reason CCTFAffiliateEvents have a different name for their times than sessions do.
        return new Date(this.session.StartDate.seconds * 1000);
      }
    },
    endTime() {
      if (this.session.EndTime) {
        return new Date(this.session.EndTime.seconds * 1000);
      } else {
        return new Date(this.session.EndDate.seconds * 1000);
      }
    },
    startDate() {
      return formatDate(this.startTime);
    },
    endDate() {
      return formatDate(this.endTime);
    },
    startDateTime() {
      return formatTime(this.startTime);
    },
    endDateTime() {
      return formatTime(this.endTime);
    },
  },
};
</script>
